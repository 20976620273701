<template>
        <section class="about-section">   
            <v-layout justify-space-around justify-center class="about-layout" :class="{'mobile-layout' :is_screen_small}">
                <v-flex  :class="is_screen_small ? 'xs12 sm12' : 'xs9 sm9'">
                        <v-card flat tile class="transparent" color="" height="100%"  column align="center" justify="center"   >
                             <v-row no-gutters class="blog-row-holder">
                                        <v-col lg="3" md="3" sm="12" xs="12" class="hidden-sm-and-down">
                                            <v-card flat color="" tile height="100%"  column align="right" justify="right"  class="transparent">
                                                <v-card-text class="">
                                                    <v-card tile flat class="parallax-blog-text transparent">
                                                        <img :src="`${s3Path}whiteRabbit.png`" height="200" class="">
                                                    </v-card>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="6" md="6" sm="12" xs="12" cols="12">
                                            <v-card color="" flat tile xs12 sm12  class="transparent parallax-blog-text text-left" dark>
                                                <v-card-text>
                                                       <span class="zamu-heading slider-about-header" :class="{'mobile-text' :is_screen_small}"><span class="slider-subheader">keeping you </span>together </span>
                                                       <span class="zamu-normal slider-about-header" :class="{'mobile-text' :is_screen_small}"><span class="slider-subheader">even when </span> you are apart </span>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'slider',
    mixins: [colorMixin, coreMixin],


}
</script>

<style>
.about-section {
     background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/lady.png') !important;
    background-position: center center !important;
    background-color: rgb(67 35 125 / 100%);

}
.mobile-layout {
    background: rgb(67 35 125 / 100%) !important;

}
.mobile-text {
    font-size: 27px;
}

</style>